<template>
  <div class="main">
    <van-form @submit="onSubmit" style="margin-top:10px">
      <van-cell-group inset>
        <van-field name="radio">
          <template #input>
            <van-radio-group v-model="form.action" direction="horizontal">
              <van-radio name="success">通过</van-radio>
              <van-radio name="fail">不通过</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          is-link
          v-if="form.action == 'success'"
          readonly
          v-model="seeForm.local_checker"
          name="picker"
          @click="showUserPicker = true"
          label="现场检查人"
          placeholder="现场检查人"
          :rules="[{ required: true, message: '请选择现场检查人' }]"
        />
        <van-field
          is-link
          v-if="form.action == 'success' && (typeId == 7 || typeId == 8)"
          readonly
          v-model="seeForm.gas_checker"
          name="picker"
          @click="showUgasCheckerPicker = true"
          label="气体检测班组"
          :rules="
            typeId == 7 || typeId == 8
              ? [{ required: true, message: '请选择气体检测班组' }]
              : null
          "
        />
        <van-field
          is-link
          v-if="form.action == 'success' && (typeId == 7 || typeId == 8)"
          readonly
          v-model="seeForm.gas_checker_type"
          name="picker"
          @click="showGasTypePicker = true"
          label="检测类型"
          placeholder="检测类型"
          :rules="
            typeId == 7 || typeId == 8
              ? [{ required: true, message: '请选择检测类型' }]
              : null
          "
        />
        <van-field
          is-link
          v-if="form.action == 'success' && (typeId == 7 || typeId == 8)"
          readonly
          v-model="seeForm.gas_checker_frequence"
          name="picker"
          @click="showfrequencePicker = true"
          label="检测频率"
          placeholder="检测频率"
          :rules="
            typeId == 7 || typeId == 8
              ? [{ required: true, message: '请选择检测频率' }]
              : null
          "
        />
        <van-field
          is-link
          type="digit"
          v-model="form.rfid"
          label="rfid"
          placeholder="rfid"
          v-if="form.action == 'success'"
        >
          <template #button>
            <van-button size="mini" type="primary" @click="startRfidServer"
              >扫描</van-button
            >
          </template>
        </van-field>
        <van-field
          is-link
          v-if="form.action == 'success'"
          v-model="form.gas_inspection_location"
          label="检查区域"
          placeholder="多个检查区域之间英文逗号分割 "
          :rules="
            typeId == 7 || typeId == 8
              ? [{ required: true, message: '请选择填写检查区域' }]
              : null
          "
        />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button
          round
          block
          type="primary"
          size="small"
          native-type="submit"
        >
          提交
        </van-button>
      </div>
    </van-form>
    <!-- 现场检测人 -->
    <van-popup v-model:show="showUserPicker" position="bottom">
      <van-picker
        :columns="options.local_checker"
        @confirm="userConfirm"
        :columns-field-names="customFieldName"
        @cancel="showUserPicker = false"
      />
    </van-popup>
    <!-- 气体检测班组 -->
    <van-popup v-model:show="showUgasCheckerPicker" position="bottom">
      <van-picker
        :columns="options.gas_checker"
        @confirm="userCheckConfrim"
        :columns-field-names="customFieldName"
        @cancel="showUgasCheckerPicker = false"
      />
    </van-popup>
    <!-- 检测类型 -->
    <van-popup v-model:show="showGasTypePicker" position="bottom">
      <van-picker
        :columns="options.gas_checker_type"
        @confirm="gasCheckConfrim"
        :columns-field-names="customFieldName"
        @cancel="showGasTypePicker = false"
      />
    </van-popup>
    <!-- 检测类型 -->
    <van-popup v-model:show="showfrequencePicker" position="bottom">
      <van-picker
        :columns="options.gas_checker_frequence"
        @confirm="frequenceConfrim"
        :columns-field-names="customFieldName"
        @cancel="showfrequencePicker = false"
      />
    </van-popup>

    <!-- rfid 展示 -->
    <van-dialog
      v-model:show="rfidShow"
      title="rfid"
      show-cancel-button
      :close-on-popstate="false"
      :before-close="beforeclose"
    >
      <van-radio-group v-model="formRfid">
        <van-radio
          v-for="items in rfidList"
          :key="items"
          :name="items"
          icon-size="20px"
          >{{ items }}</van-radio
        >
      </van-radio-group>
    </van-dialog>

    <handwritingHtml
      @writingCommit="writingCommit"
      ref="wirteDialog"
    ></handwritingHtml>
  </div>
</template>
<script>
/* eslint-disable */
import {ref,reactive,onMounted,getCurrentInstance} from 'vue';
import {useRoute,useRouter} from 'vue-router';
import Http from '../../../api/http';
import { Notify } from 'vant';
import handwritingHtml from '../../../components/handwriting.vue';
export default {
    components:{handwritingHtml},
    setup() {
        const route = useRoute();
        const router = useRouter();
        // const config = Http.config();
        const $this = getCurrentInstance();
        const taskId = ref(route.params.id);
        const typeId = ref(route.params.typeId)
        const customFieldName = {
            text: 'label',value:'value'
        };
        const options = reactive({
            local_checker:[],
            gas_checker:[],
            gas_checker_type:[],
            gas_checker_frequence:[]
        })
        // 提交参数
        const form = reactive({
            method:'saveorupdate',
            action:'success',
            t:'PTW_CETIFICATE',
            id:taskId.value,
            local_checker:'',//现场检测人
            gas_checker:'',//气体检测人
            gas_checker_type:'',//气体检测类型
            gas_checker_frequence:'',//气体检测频率
            rfid:'',
            gas_inspection_location:'',//气体检测区域
            attachment3:[],
        });
        // 显示内容
        const seeForm = reactive({
            local_checker:'',//现场检测人
            gas_checker:'',//气体检测人
            gas_checker_type:'',//气体检测类型
            gas_checker_frequence:'',//气体检测频率
        });
        
        // 现场检查人员
        const showUserPicker = ref(false);
        const userConfirm = (data) =>{
            form.local_checker = data.value;
            seeForm.local_checker = data.label;
            showUserPicker.value = false;
        }
        //气体检测班组
        const showUgasCheckerPicker = ref(false);
        const userCheckConfrim = (data) =>{
            form.gas_checker = data.value;
            seeForm.gas_checker = data.label;
            showUgasCheckerPicker.value = false;
        }
        //气体检测类型
        const showGasTypePicker = ref(false);
        const gasCheckConfrim = (data) =>{
            form.gas_checker_type = data.value;
            seeForm.gas_checker_type = data.label;
            showGasTypePicker.value = false;
        }
        //气体检测频率
        const showfrequencePicker = ref(false);
        const frequenceConfrim = (data) =>{
            form.gas_checker_frequence = data.value;
            seeForm.gas_checker_frequence = data.label;
            showfrequencePicker.value = false;
        }
        // 扫描rfid
        const startRfidServer = () =>{
            // 启动rfid模块服务
            window.rfidPlugin.ReadRfidStart(null,function() {},function() {});
        }
        // 终止rfid 服务
        const stopScan = () =>{
            window.rfidPlugin.StopRfidService(null,function() {},function() {});
        }
        // 接收rfid
        const rfidShow = ref(false);
        const rfidList = ref([]);
        const getRFID = (data) =>{
            if(rfidShow.value == false){
                rfidList.value = [];
                rfidShow.value = true;
                rfidList.value.push(data.strEPC);
            }else{
                rfidList.value.push(data.strEPC);
            }
            rfidList.value = Http.unique(rfidList.value);
        }
        // 选择rfid之后 提交
        const formRfid = ref('');
        const beforeclose = (action) =>{
            new Promise((resolve) => {
               if (action === 'confirm') {
                    if(formRfid.value != ''){
                        stopScan();//终止服务
                        form.rfid = formRfid.value.substring(formRfid.value.length-10);
                        rfidShow.value = false;
                    }else{
                        Notify({ type: 'danger', message: '请选择正确rfid' });
                        resolve(true);
                    }
                } else {
                    // 拦截取消操作
                    rfidList.value = [];
                    stopScan();
                    rfidShow.value = false;
                }
            });
        }
        // 上传签名
        const writingCommit = (res)=>{
            form.attachment3.push({document_path:res,document_code:'distribute'});
            Http.post(form,false,false)
            .then(() =>{
                router.push('/ptwDetail/'+taskId.value)
            })
            .catch(()=>{

            })
        }
        const onSubmit = () => {
            $this.refs.wirteDialog.show()
        };
        
        // 获取所有的下拉
        const getSelectList = ()=>{
            Http.get({
                t:'PTW_CETIFICATE',
                method:'listMultiPardata',
                prop:'local_checker,gas_checker,gas_checker_type,gas_checker_frequence',
                area:route.params.area
            },false,true)
            .then(res=>{
                options.local_checker = res.local_checker;
                options.gas_checker = res.gas_checker;
                options.gas_checker_type = res.gas_checker_type;
                options.gas_checker_frequence = res.gas_checker_frequence;
            })
            .catch(()=>{})

        }
        onMounted(()=>{
            getSelectList();
            window.getRFID = getRFID
        });
        return {
            taskId,
            onSubmit,
            formRfid,
            form,
            seeForm,
            showUserPicker,
            userConfirm,
            options,
            customFieldName,
            showUgasCheckerPicker,
            userCheckConfrim,
            gasCheckConfrim,
            showGasTypePicker,
            showfrequencePicker,
            frequenceConfrim,
            startRfidServer,
            stopScan,
            getRFID,
            rfidShow,
            rfidList,
            beforeclose,
            writingCommit,
            typeId
            
        }
    },
}
</script>